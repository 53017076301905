import React, { useState, useEffect } from 'react';

// const NavContext = React.createContext();
export const NavContext = React.createContext();

export const NavProvider = ({ children }) => {
  // const NavProvider = (props) => {
  // handle click outside state
  const [isActive, setIsActive] = useState(false);
  const [isToggleStyle, setToggleStyle] = useState(false);

  const toggleActive = () => {
    setIsActive((prev) => !prev);
    // setIsActive(!isActive);
    toggleSidebarStyle();
  };

  const toggleSidebarStyle = () => {
    setToggleStyle((prev) => !prev);
    // setToggleStyle(!isToggleStyle);
  };

  useEffect(() => {
    if (isToggleStyle === undefined) {
      return setToggleStyle(false);
    }
  }, [isToggleStyle]);

  return (
    <NavContext.Provider
      value={{
        isActive,
        isToggleStyle,
        setIsActive: () => setIsActive(),
        setToggleStyle: () => setToggleStyle(),
        toggleActive: () => toggleActive(),
      }}
    >
      {/* {props.children} */}
      {children}
    </NavContext.Provider>
  );
};

// /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
// export default ({ element }) => <Provider>{element}</Provider>;
// export default ({ element }) => {
//   return <Provider>{element}</Provider>;
// };

// export { NavProvider };

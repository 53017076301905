module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"catchLinks":true,"production":{"allow404Images":true,"allow401Images":true,"hardCacheMediaFiles":true},"html":{"useGatsbyImage":false,"gatsbyImageOptions":{"formats":"AVIF","layout":"CONSTRAINED","breakpoints":[500],"sizes":"(min-width: 500px) 500px, 100vw"},"createStaticFiles":false,"generateWebpImages":false,"generateAvifImages":true,"placeholderType":"none","imageQuality":90,"imageMaxWidth":null,"fallbackImageMaxWidth":1024},"type":{"Tag":{"limit":0},"Contract":{"limit":0},"Comment":{"limit":0},"PostFormat":{"limit":0},"User":{"limit":0},"UserRole":{"limit":0},"MediaItem":{"localFile":{"maxFileSizeBytes":90000000,"requestConcurrency":25,"excludeByMimeTypes":[]},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"url":"https://cms.leimageinc.com/graphql","debug":{"preview":true,"timeBuildSteps":true,"graphql":{"showQueryVarsOnError":true,"showQueryOnError":true,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"disableCompatibilityCheck":false,"throwRefetchErrors":false},"develop":{"hardCacheMediaFiles":true,"hardCacheData":true,"nodeUpdateInterval":5000},"schema":{"typePrefix":"Wp","timeout":900000,"requestConcurrency":5,"previewRequestConcurrency":5,"perPage":25,"queryDepth":15,"circularQueryLimit":5},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#0428a4","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Le Image","start_url":"/","background_color":"#f3f3f3","theme_color":"#0428a4","icon":"src/images/icon.png","cache_busting_mode":"none","display":"standalone","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-752Y94NMPR"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/dev-404-page","/404","/offline-plugin-app-shell-fallback"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

// import Provider from './provider';
// const React = require('react')
// const { Provider } = require('./provider')
import React from 'react';
// import { onRouteUpdate } from 'gatsby';
import { NavProvider } from './src/context/NavProvider';
import { ConfiguratorProvider } from './src/context/ConfiguratorProvider';

// FOUC
// in use
// export function onClientEntry() {
//   window.addEventListener('load', () => {
//     document.body.className = document.body.className.replace(/\bno-js\b/, '');
//   });
// }
// not in use
// exports.onClientEntry = () => {
//   window.addEventListener('load', () => {
//     document.body.className = document.body.className.replace(/\bno-js\b/, '');
//   });
// };

// for tawk.to widget
const excludedPaths = [
  '/wedding-packages/',
  '/wedding/elopement/',
  '/storage-packages/',
  '/packages/checkout/',
];

// fix scrolling issue and tawk.to widget
export const onRouteUpdate = ({ location }) => {
  const pathname = location.pathname;

  // handle tawk.to widget
  handleTawkToWidget(pathname);

  // fix scrolling issues
  // console.log('Route updated:', location.pathname);
  // console.log('Scroll position before:', window.scrollY);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    // console.log('Scroll position after:', window.scrollY);
  };

  const scrollToHash = () => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        // console.log('Scrolled to element:', hash);
      }
      // else {
      //   console.log('Element not found for hash:', hash);
      // }
    }
  };

  // Ensure the scroll action happens after the route update and page content is rendered
  setTimeout(() => {
    requestAnimationFrame(() => {
      if (location.hash) {
        // console.log('Scrolling to hash:', location.hash);
        scrollToHash();
      } else {
        // console.log('Scrolling to top');
        scrollToTop();
      }
    });
  }, 0); // add delay to ensure content is rendered if needed
};

// ensure tawk.to widget is hidden on specific pages
// on page refresh
export const onInitialClientRender = () => {
  // console.log('Initial client render');
  setTimeout(() => {
    handleTawkToWidget(window.location.pathname);
  }, 300);
};

const handleTawkToWidget = (pathname) => {
  // console.log('Handling Tawk.to widget for pathname:', pathname);
  if (excludedPaths.includes(pathname)) {
    // console.log('Hiding Tawk.to widget');
    // Hide Tawk.to iframes and other related elements
    const tawkIframes = document.querySelectorAll(
      // '.widget-visible'
      'iframe[src*="tawk.to"], iframe[title="chat widget"]'
    );
    tawkIframes.forEach((iframe) => {
      // console.log('CHAT IFRAME HIDING:', iframe);
      iframe.style.display = 'none';
    });

    const tawkDivs = document.querySelectorAll(
      //   // 'div[id^="chat-bubble"]'
      'div[id^="z"], div[id^="b"], div[id^="w"], div[id^="chat-bubble"]'
    );
    // );
    tawkDivs.forEach((div) => {
      //   // console.log('CHAT DIV HIDING:', div);
      div.style.display = 'none';
    });
  } else {
    console.log('Showing Tawk.to widget');
    // Show Tawk.to iframes and other related elements
    const tawkIframes = document.querySelectorAll(
      // '.widget-visible'
      'iframe[src*="tawk.to"], iframe[title="chat widget"]'
    );
    tawkIframes.forEach((iframe) => {
      // console.log('CHAT IFRAME SHOWING:', iframe);
      iframe.style.display = 'block';
      iframe.style.position = 'absolute';
    });

    const tawkDivs = document.querySelectorAll(
      //   // 'div[id^="chat-bubble"]'
      'div[id^="z"], div[id^="b"], div[id^="w"], div[id^="chat-bubble"]'
    );
    // );
    tawkDivs.forEach((div) => {
      // console.log('CHAT DIV SHOWING:', div);
      div.style.display = 'block';
    });
  }
};

export const wrapRootElement = ({ element }) => (
  <ConfiguratorProvider>
    <NavProvider>{element}</NavProvider>
  </ConfiguratorProvider>
);

// export function wrapRootElement() { return <Provider />; }
// exports.wrapRootElement = () => <Provider />
// export const wrapRootElement = Provider;
// export default wrapRootElement = Provider;
